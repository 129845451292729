import styled from 'styled-components';
import { Link } from 'gatsby';
import media from "styled-media-query";

export const AboutWrapper = styled.section`
    width: 100%;
    max-width: 1100px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    padding: 20px;

    & .coder-img {
        flex: 6;
        text-align: right;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
            width: 90%;
            position: absolute;
            left: -30em;
            -webkit-animation: slide-image 1s forwards;
            animation: slide-image 1s forwards;

          ${media.lessThan("medium")`
            width: 100%;
            position: relative;
            left: 0;
         `}
        }

        @-webkit-keyframes slide-image {
          100% {
            left: 0;
          }
        }
        @keyframes slide-image {
          100% {
            left: 0;
          }
        }
    }

    & .profile-hide {
        display: none;
    }

    & .profile {
      flex: 6;
      text-align: left;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ${media.lessThan("medium")`
      margin: 50px auto;
      flex-direction: column-reverse;
      justify-content: center;
      height: auto;
   `}
`

export const AboutTexts = styled.div`
    width: 90%;
    position: absolute;
    right: -30em;
    -webkit-animation: slide-profile 1s forwards;
    animation: slide-profile 1s forwards;

    ${media.lessThan("medium")`
      position: relative;
      width: auto;
      right: 0;
    `}

    @-webkit-keyframes slide-profile {
        100% {
        right: 0;
        }
    }
    @keyframes slide-profile {
        100% {
        right: 0;
        }
    }
`

export const AboutTitle = styled.h1`
    color: #FFF;
    font-size: 2.8rem;
    margin: 0 0 10px;
    
    ${media.lessThan("medium")`
      font-size: 2.5rem;
    `}
`

export const AboutDescription = styled.p`
    font-weight: 400;
    font-size: 1rem;

    ${media.lessThan("medium")`
      font-size: 1.1rem;
    `}
`

export const AboutLink = styled(Link)`
    width: 50%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-background);
    background: var(--color-secondary);
    border-radius: 0.4rem;
    padding: 0.8rem;
    border: 0.1rem solid var(--color_text);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s ease-in;
    
    &:hover {
        background: transparent;
        color: var(--color-text);
    }

    ${media.lessThan("medium")`
      display: none;
    `}
`
















