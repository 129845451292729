import styled from 'styled-components';
import media from "styled-media-query";

export const InformationWrapper = styled.div`
    width: 100%;
    max-width: 1050px;
    padding: 0 40px;
    margin: 20px auto 100px;
    display: flex;
    justify-content: center;

    ${media.lessThan("medium")`
        margin: 80px 0 0;
        padding: 0;
    `}
`

export const InformationSection = styled.section`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;
    align-items: center;
    justify-content: space-between;
    height: 400px;

    & div {
        position: relative;
    }

    & .information-hide {
        display: none;
    }

    & .information-card {
        margin: 30px 0;
        padding: 50px 30px;
        border-radius: 8px;
        transition: 0.3s;
        background: var(--color-background-second);
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column-reverse;
        -webkit-animation: slide-text 1s forwards;
        animation: slide-text 1s forwards;
    }

    & .information-card:nth-child(1) {
        margin-top: 80px;
        bottom: -20em;
    }

    & .information-card:nth-child(2) {
        margin-top: 0;
        bottom: -40em;
    }

    & .information-card:nth-child(3) {
        margin-top: 80px;
        bottom: -60em;
    }

    @-webkit-keyframes slide-text {
        100% {
          bottom: 0;
        }
    }

    @keyframes slide-text {
        100% {
          bottom: 0;
        }
    }

    ${media.lessThan("medium")`
        width: 100vw;
        display: grid;
        padding: 0 20px;
        grid-template-columns: 1fr;
        gap: 20px;
        height: auto;

        & .information-card {
            width: 100%;
            margin: 20px 0;
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            flex-direction: column-reverse;
            padding: 50px 30px;
            border-radius: 8px;
            transition: 0.3s;
            background: #1d1d1f;
        }

        & .information-card:nth-child(1) {
            margin-top: 0;
            bottom: -20em;
         }
         & .information-card:nth-child(2) {
            margin-top: 0;
            bottom: -40em;
         }
         & .information-card:nth-child(3) {
            margin-top: 0;
            bottom: -60em;
         }


    `}
`

export const InformationText = styled.div`
    width: 100%;
    
    & p {
        font-size: 1.3rem;
        font-weight: bold;
        margin: 20px 0 0;
        color: #fff;
    }

    & span {
        font-size: 0.8rem;
    }

    ${media.lessThan("medium")`
        & p {
            font-size: 1.6rem;
            font-weight: bold;
            margin-top: 25px;
        }

        & span {
            font-size: 0.9rem;
        }
    `}
`

export const InformationImage = styled.div`
    width: 100%;
    text-align: center;

    & img {
        width: 30%;
    }
`

