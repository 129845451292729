import styled from 'styled-components';
import media from "styled-media-query";

export const ExclusiveWrapper = styled.div`
    width: 100%;
    max-width: 900px;
    margin: 80px auto;
    padding: 60px;
    border-radius: 8px;
    border: 2px solid var(--color-secondary);
    box-shadow: 0 0 0.2rem var(--color-secondary);

    ${media.lessThan("medium")`
        width: auto;
        margin: 40px 20px;
        padding: 15px;
    `}
`

export const ExclusiveSection = styled.section`
    text-align: left;
`

export const ExclusiveTexts = styled.div`
    width: 70%;

    ${media.lessThan("medium")`
        width: 100%;
   `}
`

export const ExclusiveTitle = styled.h1`
    color: var(--color-secondary);
    font-size: 2rem;
    margin: 0;
    
    ${media.lessThan("medium")`
      font-size: 1.8rem;
    `}
`

export const ExclusiveDescription = styled.p`
    font-size: 1.2rem;
    margin: 10px 0;
    
    ${media.lessThan("medium")`
      font-size: 1rem;
    `}
`

export const ExclusiveForm = styled.form`
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
    display: grid;
    grid-template-columns: 35% 35% 20%;
    align-items: center;
    text-align: left;
    gap: 20px;

    input {
        color: #FFF;
        height: 48px;
        padding: 0 15px;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid var(--color-secondary);
        background: transparent;

        ${media.lessThan("medium")`
            height: 56px;
        `}
    }

    button {
        width: 100%;
        height: 48px;
        color: var(--color-background);
        background: var(--color-secondary);
        border-radius: 0.4rem;
        padding: 0.4rem 1.5rem;
        border: 0.1rem solid var(--color_text);
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: 0.2s ease-in;

        ${media.lessThan("medium")`
            height: 56px;
        `}

        &:hover {
            background: transparent;
            color: var(--color-text);
        }
    }

    ${media.lessThan("medium")`
        grid-template-columns: 1fr;
    `}
`