import React from "react";

import TimeImg from "../../images/home/time.png";
import SafeImg from "../../images/home/safe.png";
import SuportImg from "../../images/home/suport.png";

import * as S from "./styled";

function Information(props) {
  return (
    <S.InformationWrapper>
      <S.InformationSection>
        <div className={props.classInformation}>
          <S.InformationText>
            <p>Faça no seu tempo</p>
            <span>Assista o curso no seu tempo, sem pressa, sem nenhum prazo para expirar.</span>
          </S.InformationText>
          <S.InformationImage>
            <img src={TimeImg} alt="Ícone de Relógio" />
          </S.InformationImage>
        </div>
        <div className={props.classInformation}>
          <S.InformationText>
            <p>Totalmente seguro</p>
            <span>A compra será com total segurança!</span>
          </S.InformationText>
          <S.InformationImage>
            <img src={SafeImg} alt="Ícone de Seguro" />
          </S.InformationImage>
        </div>
        <div className={props.classInformation}>
          <S.InformationText>
            <p>Suporte Dedicado</p>
            <span>Conte com um time de especialistas e tire todas as suas dúvidas relativas ao curso</span>
          </S.InformationText>
          <S.InformationImage>
            <img src={SuportImg} alt="Ícone de Multimidia" />
          </S.InformationImage>
        </div>
      </S.InformationSection>
    </S.InformationWrapper>
  );
}

export default Information;