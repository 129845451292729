import React, { useState, useEffect } from "react";
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import Presentation from "../components/HomePresentation"
import HomeInformation from "../components/HomeInformation";
import HomeAbout from "../components/HomeAbout";
import HomeImportant from "../components/HomeImportant";
import HomeSkill from "../components/HomeSkill";
import HomeExclusive from "../components/HomeExclusive";

const IndexPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const [classInformation, setClassInformation] = useState("information-hide");
  const [classImage, setClassImage] = useState("profile-hide");
  const [classProfile, setClassProfile] = useState("profile-hide");
  const [classImportant, setClassImportant] = useState("text-hide");

  async function handleScrollAbout() {
    let scrollposition = document.documentElement.scrollTop;

    if (scrollposition > 300 && scrollposition < 450) {
      setClassInformation("information-card");
    } else if (scrollposition > 450 && scrollposition < 999) {
      setClassImage("coder-img");
      setClassProfile("profile");
    } else if (scrollposition > 1000 && scrollposition < 1200) {
      setClassImportant("card-text");
    } 
  }

  useEffect(() => {
    window.onscroll = () => handleScrollAbout();
  }, []);
  
  return (
    <Layout  title={siteTitle}>
      <Seo />
      <Presentation />
      <HomeInformation classInformation={classInformation} />
      <HomeAbout classImage={classImage} classProfile={classProfile} />
      <HomeImportant classImportant={classImportant} />
      <HomeSkill />
      <HomeExclusive />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
