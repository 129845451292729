import React, { Component } from "react";

import { Html5 } from "@styled-icons/boxicons-logos/Html5";
import { Css3 } from "@styled-icons/boxicons-logos/Css3";
import { Javascript } from "@styled-icons/boxicons-logos/Javascript";
import { ReactLogo as ReactJS } from "@styled-icons/boxicons-logos/ReactLogo";
import { Flutter } from "@styled-icons/boxicons-logos/Flutter";
import { Git } from "@styled-icons/boxicons-logos/Git";
import { Nodejs } from "@styled-icons/boxicons-logos/Nodejs";
import { Mongodb } from "@styled-icons/simple-icons/Mongodb";



import * as S from "./styled";

class Skill extends Component {
  render() {
    return (
      <S.SkillWrapper>
        <h1>Ferramentas que você irá aprender!</h1>
        <S.SkillSection>
          <S.SkillCard>
            <S.SkillTitle>
              <p>HTML5</p>
            </S.SkillTitle>
            <S.SkillImage>
              <Html5 />
            </S.SkillImage>
          </S.SkillCard>
          <S.SkillCard>
            <S.SkillTitle>
              <p>CSS3</p>
            </S.SkillTitle>
            <S.SkillImage>
              <Css3 />
            </S.SkillImage>
          </S.SkillCard>
          <S.SkillCard>
            <S.SkillTitle>
              <p>Javascript</p>
            </S.SkillTitle>
            <S.SkillImage>
              <Javascript />
            </S.SkillImage>
          </S.SkillCard>
          <S.SkillCard>
            <S.SkillTitle>
              <p>React</p>
            </S.SkillTitle>
            <S.SkillImage>
              <ReactJS />
            </S.SkillImage>
          </S.SkillCard>
          <S.SkillCard>
            <S.SkillTitle>
              <p>Flutter</p>
            </S.SkillTitle>
            <S.SkillImage>
              <Flutter />
            </S.SkillImage>
          </S.SkillCard>
          <S.SkillCard>
            <S.SkillTitle>
              <p>Git</p>
            </S.SkillTitle>
            <S.SkillImage>
              <Git />
            </S.SkillImage>
          </S.SkillCard>
          <S.SkillCard>
            <S.SkillTitle>
              <p>Node</p>
            </S.SkillTitle>
            <S.SkillImage>
              <Nodejs />
            </S.SkillImage>
          </S.SkillCard>
          <S.SkillCard>
            <S.SkillTitle>
              <p>MongoDB</p>
            </S.SkillTitle>
            <S.SkillImage>
              <Mongodb />
            </S.SkillImage>
          </S.SkillCard>
        </S.SkillSection>
      </S.SkillWrapper>
    );
  }
}

export default Skill;