import styled from 'styled-components';
import media from "styled-media-query";
import { Link } from 'gatsby';

export const PresentationWrapper = styled.section`
    width: 100%;
    max-width: 1150px;
    padding: 20;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    background-position: center;
    background-size: cover;
    gap: 50px;

    ${media.lessThan("large")`
      padding: 0 20px;
      margin: 10px auto;
    `}

    ${media.lessThan("medium")`
      display: grid;
      justify-content: center;
    `}
`

export const PresentationText = styled.div`
    flex: 6;

    ${media.lessThan("medium")`
        padding: 20px 0;
    `}
`

export const PresentationTitle = styled.h1`
    font-size: 5rem;
    margin: 0;
    letter-spacing: -0.2rem;
    font-weight: 800;
    background: -webkit-linear-gradient(#1cffb3, #5996f0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-right: 2px solid #5996f0;
    white-space: nowrap;
    overflow: hidden;

    animation: blinkCursor 500ms steps(40) infinite normal,
    typing 2s steps(40) 0.8s normal both;

    @keyframes typing {
      from {
        width: 0%;
      }
      to {
        width: 65%;
      }
    }

    @keyframes blinkCursor {
      from {
        border-right-color: #5996f0;
      }
      to {
        border-right-color: transparent;
      }
    }

    ${media.lessThan("large")`
      @keyframes typing {
        from {
          width: 0%;
        }
        to {
          width: 100%;
        }
      }
    `}

    ${media.lessThan("medium")`
      width: 100%;
      margin-top: 0rem;
    `}

`

export const PresentationSubTitle = styled.h2`
    font-size: 2.8rem;
    margin: 0.5rem 0 1rem;
    letter-spacing: -0.2rem;
    font-weight: 800;
    //background: -webkit-linear-gradient(#1cffb3, #5996f0);
    //-webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;

    ${media.lessThan("medium")`
        width: 100%;
        padding: 0px 2px;
        font-size: 2.4rem;
        margin: 0px 0px 1rem;
        letter-spacing: -0.1rem;
    `}
`
export const PresentationDescription = styled.p`
    font-size: 1.2rem;
    line-height: 32px;
    margin: 10px 0 40px;
    font-weight: 500;
`

export const PresentationLink = styled(Link)`
    text-decoration: none;
    background: -webkit-linear-gradient(#1cffb3, #5996f0);
    color: var(--color-background);
    border-radius: 0.4rem;
    padding: 0.7rem 4rem;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s ease-in;

    &:hover {
        background: transparent;
        color: var(--color-text);
    }
`

export const PresentationImage = styled.div`
    flex: 6;
    display: flex;
    justify-content: center;

    ${media.lessThan("medium")`
       padding: 20px 0;
    `}

    & img {
      width: 100%;
      border-radius: 8px;
    }
`
