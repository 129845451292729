import React from "react";
import student from "../../images/home/student.png";

import * as S from "./styled";

function About(props) {
  return (
    <S.AboutWrapper>
      <div className={props.classImage}>
        <img src={student} alt="Developer" />
      </div>
      <div className={props.classProfile}>
        <S.AboutTexts>
          <S.AboutTitle>Aprendendo sempre!</S.AboutTitle>
          <S.AboutDescription>
            Não importa se você é iniciante ou um dev experiente, nós da
            DevLoad nos comprometemos a te ajudar à evoluir rápido com os
            melhores cursos do mercado, começando do zero ao avançado.
          </S.AboutDescription>
          <S.AboutLink to="/courses">Iniciar na programação!</S.AboutLink>
        </S.AboutTexts>
      </div>
    </S.AboutWrapper>
  );
}

export default About;
