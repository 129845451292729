import styled from 'styled-components';
import media from "styled-media-query";

export const SkillWrapper = styled.div`
    margin: 40px auto;
    max-width: 800px;

    & h1 {
      text-align: center;
      max-width: 700px;
      font-size: 3rem;
      margin: 0 auto 30px;
      color: #fff;

      ${media.lessThan("medium")`
        font-size: 2.5rem;
      `}
    }

    ${media.lessThan("medium")`
        width: 100%;
        margin-top: 0;
        position: relative;
        left: 0;
        padding: 20px;
    `}
`

export const SkillSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  ${media.lessThan("medium")`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
  `}
`

export const SkillCard = styled.div`
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  flex-direction: column-reverse;
`

export const SkillTitle = styled.div`
  width: 100%;

  & p {
    font-size: 0.8rem;
    margin-top: 10px;
    color: var(--color-text);

    ${media.lessThan("medium")`
      font-size: 1rem; 
    `}
  }
`

export const SkillImage = styled.div`
  width: 100%;
  text-align: center;

  & svg {
    width: 2.5rem;
    color: var(--color-text);

    ${media.lessThan("medium")`
      width: 3rem; 
    `}
  }
`

