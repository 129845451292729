import React, { useState } from "react";
import axios from "axios";

import * as S from "./styled";

function Exclusive() {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
      axios({
        method: "post",
        url: process.env.REACT_APP_URL_FORM,
        data: new FormData(form)
      })
        .then(r => {
          alert("Contato enviado com sucesso!!!");
          handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          alert("Uhhh! Elgo deu errado, tente novamente!!!");
          handleServerResponse(false, r.response.data.error, form);
        });
  };
  return (
    <>
      <S.ExclusiveWrapper>
        <S.ExclusiveSection>
          <S.ExclusiveTexts>
            <S.ExclusiveTitle>Fique por dentro!</S.ExclusiveTitle>
            <S.ExclusiveDescription>
              Saiba em primeira mão sempre que surgir novidades sobre o mundo da
              tecnologia!
            </S.ExclusiveDescription>
          </S.ExclusiveTexts>
          <S.ExclusiveForm onSubmit={handleOnSubmit}>
            <input type="text" name="name" placeholder="Seu nome" />
            <input type="email" name="email" placeholder="Digite seu melhor E-mail" />
            <button className="exclusive-button" type="submit">
              Enviar
            </button>
          </S.ExclusiveForm>
        </S.ExclusiveSection>
      </S.ExclusiveWrapper>
    </>
  );
}

export default Exclusive;
