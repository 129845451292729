import styled from 'styled-components';
import media from "styled-media-query";

export const ImportantWrapper = styled.div`
  margin: 100px 0;
  background-position: center;
  background-size: cover;

  ${media.lessThan("medium")`
      margin: 80px 0 0;
      padding: 0;
  `}
`

export const ImportantSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ImportantCard = styled.div`
  width: 100%;
  max-width: 1050px;
  height: 300px;
  position: relative;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: 0.3s;
  margin: 50px 0;
  text-align: center;

  ${media.lessThan("medium")`
    text-align: left;
    margin: 10px 0;
    height: auto;
  `}

  & .text-hide {
    display: none;
  }

  & .card-text {
    width: 70%;
    position: absolute;
    bottom: -30rem;
    animation: slide-text 1s forwards;

    ${media.lessThan("medium")`
      width: 100%;
      position: relative;
      bottom: 0;
    `}

  }

  @-webkit-keyframes slide-text {
    100% {
      bottom: 0;
    }
  }

  @keyframes slide-text {
    100% {
      bottom: 0;
    }
  }
`

export const ImportantTitle = styled.h1`
  color: #FFF;
  font-size: 3rem;
  margin-bottom: 10px;

  ${media.lessThan("medium")`
    font-size: 2.5rem;
  `}
`

export const ImportantDescription = styled.p`
  font-weight: 400;
  font-size: 1.2rem;
  color: #FFF;

  ${media.lessThan("medium")`
    font-size: 1.1rem;
  `}
`