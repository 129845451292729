import React, { Component } from "react";
import { StaticImage } from "gatsby-plugin-image"

import * as S from './styled';

class Presentation extends Component {
  render() {
    return (
      <S.PresentationWrapper>
        <S.PresentationText>
          <S.PresentationTitle>Devload</S.PresentationTitle>
          <S.PresentationSubTitle>Faz você pensar fora da caixa.</S.PresentationSubTitle>
          <S.PresentationDescription>
            Transforme a sua vida e a de milhares, milhões ou até bilhões de
            pessoas com tecnologia.
          </S.PresentationDescription>
          <S.PresentationLink to="/courses">Iniciar!</S.PresentationLink>
        </S.PresentationText>
        <S.PresentationImage>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/home/presentation.svg"
            quality={95}
            alt="Apresentação"
          />
        </S.PresentationImage>
      </S.PresentationWrapper>
    );
  }
}

export default Presentation;
