import React from "react";

import BannerImg from '../../images/home/banner.jpg'

import * as S from "./styled";

function Important(props) {
  return (
    <S.ImportantWrapper
      style={{
        backgroundImage: `
        linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url('${BannerImg}')`,
      }}
    >
      <S.ImportantSection>
        <S.ImportantCard>
          <div className={props.classImportant}>
            <S.ImportantTitle>Por que aprender a programar?</S.ImportantTitle>
            <S.ImportantDescription>
              Os cargos de Desenvolvedor de Softwares e Desenvolvedor de
              Aplicativos devem ser dois dos mais buscados pelas empresas nos
              próximos anos, segundo o relatório do Fórum Econômico Mundial.
            </S.ImportantDescription>
          </div>
        </S.ImportantCard>
      </S.ImportantSection>
    </S.ImportantWrapper>
  );
}

export default Important;
